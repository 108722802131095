import { formatCurrency, Stack, Text } from '@fairlo/ui-kit';
import { Translation } from 'react-i18next';

import productTiersSECredit from '../../../constants/SE/productTiersSECredit';
import { QaLocales } from '../../../types/questionsAndAnswers';
import { formatDecimalDigits } from '../../../utils/number';
import Modal from '../Modal';
import { ModalParagraph, TermsHeadingText } from './shared';
import { Table, TermsStyledHeading } from './styled';

/* prettier-ignore */
const tableValues = [
  ['0 - 29 999',      0 ],
  ['30 000 - 39 999', 9 ],
  ['40 000 - 49 999', 19],
  ['50 000 - 59 999', 19],
  ['60 000 - 69 999', 19],
  ['70 000',          29]
];

const sekki: QaLocales = {
  'sv-SE': {
    name: 'Sekki',
    readMore: false,
    questions: [
      {
        question: 'Kreditgivarens/kreditförmedlarens namn och kontaktuppgifter',
        answer: (
          <Stack spacing={8}>
            <div>
              <TermsHeadingText as="h3">Kreditgivare</TermsHeadingText>
              <Text as="span">Nordiska Kreditmarknadsaktiebolaget (publ)</Text>
            </div>
            <div>
              <TermsHeadingText as="h3">Adress</TermsHeadingText>
              <Text as="span">Box 173,</Text>
            </div>
            <Text as="span">101 23 Stockholm</Text>
            <div>
              <TermsHeadingText as="h3">Telefon</TermsHeadingText>
              <Text as="span">08 23 28 00</Text>
            </div>
            <div>
              <TermsHeadingText as="h3">E-post</TermsHeadingText>
              <Text as="span">info@nordiska.se</Text>
            </div>
            <div>
              <TermsHeadingText as="h3">Webbadress</TermsHeadingText>
              <Text as="span">www.nordiska.se</Text>
            </div>
            <div>
              <TermsHeadingText as="h3">
                I tillämpliga fall: <br />
                Kreditförmedlare
              </TermsHeadingText>
              <Text as="span">Fairlo AB (nedan &quot;Fairlo&quot;)</Text>
            </div>
            <div>
              <TermsHeadingText as="h3">Adress</TermsHeadingText>
              <Text as="span">Birger Jarlsgatan 34,</Text>
            </div>
            <Text as="span">114 29 Stockholm</Text>
            <div>
              <TermsHeadingText as="h3">E-post</TermsHeadingText>
              <Text as="span">kundservice@fairlo.se</Text>
            </div>
            <div>
              <TermsHeadingText as="h3">Webbadress</TermsHeadingText>
              <Text as="span">www.fairlo.se</Text>
            </div>
          </Stack>
        )
      },
      {
        question: 'Beskrivning av huvuddragen i kreditprodukten',
        answer: (
          <Stack spacing={8}>
            <div>
              <TermsHeadingText as="h3">Typ av kredit</TermsHeadingText>
              <ModalParagraph>
                Revolverande kontokredit som ger Kredittagaren rätt att nyttja ett Kreditutrymme. Krediten ges
                utan säkerhet.
              </ModalParagraph>
            </div>

            <div>
              <TermsHeadingText as="h3">Det sammanlagda kreditbeloppet</TermsHeadingText>
              <ModalParagraph>
                <em>
                  Detta är den övre kreditgräns eller det totala belopp som ställs till förfogande genom
                  kreditavtalet.
                </em>
              </ModalParagraph>
            </div>
            <ModalParagraph>
              Kredittagaren kan ansöka om kredit på något av följande belopp i svenska kronor:{' '}
              {Object.values(productTiersSECredit)
                .map(({ amount }) => formatCurrency(amount, { currency: 'SEK', displaySign: false }))
                .join(', ')}
            </ModalParagraph>
            <ModalParagraph>
              Vilket utrymme Kredittagaren kan beviljas beror dels på vilket Kreditutrymme kredittagaren har
              ansökt om och dels vilket belopp Kredittagaren beviljats efter genomförd kreditprövning.
            </ModalParagraph>

            <div>
              <TermsHeadingText as="h3">Villkoren för kreditutnyttjandet</TermsHeadingText>
              <ModalParagraph>
                <em>Här anges när och hur pengarna kan lyftas.</em>
              </ModalParagraph>
            </div>
            <ModalParagraph>
              Vid beviljad ansökan ges Kredittagaren möjlighet att göra uttag till sitt bankkonto inom ramen
              för beviljat Kreditutrymme.
            </ModalParagraph>
            <ModalParagraph>
              Om Kredittagaren utnyttjar hela Kreditutrymmet, men önskar göra ytterligare uttag, måste
              Kredittagaren antingen ansöka om ett högre Kreditutrymme eller betala hela eller delar av
              skulden.
            </ModalParagraph>

            <div>
              <TermsHeadingText as="h3">Kreditavtalets löptid</TermsHeadingText>
              <ModalParagraph>
                Avtalet löper tills vidare och avslutas när någon av parterna säger upp det.
              </ModalParagraph>
            </div>

            <div>
              <TermsHeadingText as="h3">
                Avbetalningar och, i förekommande fall, i vilken ordning dessa ska fördelas
              </TermsHeadingText>
              <ModalParagraph>
                Kredittagaren ska återbetala Krediten, ränta och eventuella avgifter enligt Betalningsplanen.
                Kredittagaren kan när som helst se hur mycket Kredittagaren ska betala enligt Betalningsplanen
                genom att logga in på Mina sidor på www.fairlo.se.
              </ModalParagraph>
            </div>
            <ModalParagraph>
              Kredittagaren kan förändra Månadsbeloppet genom att registrera och godkänna Uttag, göra en
              Extraamortering eller ändra återbetalningstiden. Innan Kredittagaren gör ett Uttag eller en
              Extraamortering får Kredittagaren besked om hur den totala skulden kommer att förändras.
              Kredittagaren får samtidigt en ny Betalningsplan och information om ett nytt Månadsbelopp. När
              Kredittagaren bekräftar och genomför Uttaget eller Extraamorteringen blir det nya Månadsbeloppet
              det senast avtalade beloppet som Kredittagaren ska betala. Den nya Betalningsplanen ersätter den
              tidigare avtalade betalningsplanen.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren har också möjlighet att, ändra Månadsbeloppet genom att logga in på Mina sidor på
              www.fairlo.se och där välja att justera återbetalningstiden, om inte Kredittagaren är försenad
              med att betala ett eller flera Månadsbelopp. Hur lång återbetalningstid Kredittagaren kan välja
              beror på hur stor del av Kreditutrymmet som Kredittagaren har utnyttjat. Innan
              återbetalningstiden förändras får Kredittagaren ett utkast på hur den totala skulden kommer att
              påverkas samt en ny Betalningsplan med ett nytt Månadsbelopp. När Kredittagaren har bekräftat
              och genomfört ändringen av återbetalningstiden blir det nya Månadsbeloppet det belopp som
              Kredittagaren ska betala. Den nya Betalningsplanen ersätter den föregående Betalningsplanen och
              Månadsbeloppet däri. Den nya Betalningsplanen gäller ’från och med den tidpunkt som
              Kredittagaren bekräftar och godkänner ändringen.
            </ModalParagraph>
            <ModalParagraph>
              Kredittagaren har rätt att när som helst betala tillbaka hela eller delar av krediten till
              Kreditgivaren utan kostnad utöver upplupen ränta för den period som Kredittagaren utnyttjat
              Krediten. Detta gör Kredittagaren genom att logga in på Mina sidor på www.fairlo.se och följa
              instruktionerna.
            </ModalParagraph>
            <ModalParagraph>
              När Kredittagaren gör transaktioner som medför förändringar av storleken på Krediten och
              Månadsbeloppet får Kredittagaren en bekräftelse på transaktionen till sin registrerade
              e-postadress.
            </ModalParagraph>
            <ModalParagraph>
              Vid inbetalningar sker avräkning först mot förfallna avgifter, kostnader och räntor, därefter
              utestående kapitalskuld.
            </ModalParagraph>

            <div>
              <TermsHeadingText as="h3">Det totala belopp som du ska betala</TermsHeadingText>
              <ModalParagraph>
                <em>
                  Detta är det lånade kapitalet plus ränta och eventuella kostnader i samband med krediten.
                </em>
              </ModalParagraph>
            </div>
            <ModalParagraph>
              Det totala beloppet att återbetala beräknas på det nyttjade Kreditutrymmet, räntenivå, samt vald
              betalningsplan. Ett representativt exempel finns i avsnitt 3.
            </ModalParagraph>

            <div>
              <TermsHeadingText as="h3">
                I tillämpliga fall: <br />
                Krediten beviljas i form av betalningsanstånd för en vara eller en tjänst eller är kopplad
                till leveranser av specifika varor eller tillhandahållande av en tjänst
              </TermsHeadingText>
              <ModalParagraph>Varans/tjänstens namn</ModalParagraph>
            </div>
            <Stack>
              <ModalParagraph>Ej tillämpbart</ModalParagraph>
              <ModalParagraph>Kontantpris</ModalParagraph>
              <ModalParagraph>Ej tillämpbart</ModalParagraph>
            </Stack>

            <div>
              <TermsHeadingText as="h3">
                I tillämpliga fall: <br />
                Begärda säkerheter
                <ModalParagraph>
                  <em>
                    Detta är en beskrivning av den säkerhet som ska ställas i samband med kreditavtalet.
                  </em>
                </ModalParagraph>
              </TermsHeadingText>
            </div>
            <ModalParagraph>Krediten löper utan säkerhet.</ModalParagraph>

            <div>
              <TermsHeadingText as="h3">
                I tillämpliga fall: <br />
                <em>Avbetalningarna medför inte omedelbar amortering av kapitalet.</em>
              </TermsHeadingText>
              <ModalParagraph>
                Vid betalning av Krediten sker avräkning först mot förfallna avgifter, kostnader och räntor,
                därefter utestående kapitalskuld.
              </ModalParagraph>
            </div>
          </Stack>
        )
      },
      {
        question: 'Kreditkostnader',
        answer: (
          <Translation>
            {t => (
              <Stack spacing={8}>
                <TermsHeadingText as="h3">
                  Kreditränta eller i tillämpliga fall de olika krediträntor som gäller för kreditavtalet
                </TermsHeadingText>

                <ModalParagraph>
                  Nedanstående krediträntor gäller för avtal ingångna 2023-05-10 eller senare.
                </ModalParagraph>

                <ul>
                  {[...Object.values(productTiersSECredit), { amount: 100_000, interest: 24 }].map(
                    ({ amount, interest }) => (
                      <li key={amount}>
                        <Text as="span">
                          Nominell årsränta på ett kreditutrymme om {t('Common:moneyWithoutSign', { amount })}{' '}
                          kronor är {formatDecimalDigits(interest, 2)} %
                        </Text>
                      </li>
                    )
                  )}

                  <li>
                    <ModalParagraph>
                      Räntan är rörlig, vilket innebär att Kreditgivaren har rätt att omedelbart ändra
                      räntesatsen i den utsträckning som det motiveras av kreditpolitiska beslut, ökade
                      upplåningskostnader för Kreditgivaren eller andra kostnadsökningar som Kreditgivaren
                      inte skäligen kunde förutse när avtalet ingicks. Kreditgivaren förbehåller sig även
                      rätten att ändra räntan i andra situationer, så länge sådan höjning sker i
                      överensstämmelse med vid var tid gällande konsumentkreditlagstiftning. Kreditgivaren
                      underrättar Kredittagaren om en räntehöjning innan den börjar gälla samt hur
                      Kredittagarens totala skuld och Månadsbelopp påverkas av ränteändringen.
                    </ModalParagraph>
                  </li>
                </ul>
                <div>
                  <TermsHeadingText as="h3">Effektiv ränta</TermsHeadingText>
                  <ModalParagraph>
                    <em>
                      Detta är totalkostnaden uttryckt som den effektiva räntan av det samlade kreditbeloppet.
                    </em>
                  </ModalParagraph>
                </div>
                <ModalParagraph>
                  <em>Den effektiva räntan anges för att hjälpa dig att jämföra olika erbjudanden.</em>
                </ModalParagraph>
                <ModalParagraph>Räkneexempel vid låneutrymme på 30 000 kr:</ModalParagraph>
                <ModalParagraph>
                  Räkneexempel vid låneutrymme på 30 000 kr och en nominell rörlig ränta på 29%.
                </ModalParagraph>
                <ModalParagraph>
                  Med ett månadsbelopp om 961 kr i 60 månader blir den effektiva räntan 33,82% och totalt att
                  återbetala blir 57 676 kr.
                </ModalParagraph>

                <Stack>
                  <div>
                    <TermsHeadingText as="h3">
                      Är det, för att erhålla krediten eller för att erhålla den enligt villkoren i
                      marknadsföringen, obligatoriskt att ingå avtal om
                    </TermsHeadingText>
                    <ModalParagraph>— en försäkring som säkrar krediten, eller</ModalParagraph>
                  </div>
                  <ModalParagraph>Nej</ModalParagraph>
                  <ModalParagraph>— någon annan kompletterande tjänst?</ModalParagraph>
                  <ModalParagraph>Nej</ModalParagraph>
                  <ModalParagraph>
                    <em>
                      Om kostnaderna för dessa tjänster inte är kända av kreditgivaren ska de inte inkluderas
                      i den effektiva räntan.
                    </em>
                  </ModalParagraph>
                </Stack>

                <TermsHeadingText as="h3">Härtill hörande kostnader</TermsHeadingText>

                <div>
                  <TermsHeadingText as="h3">
                    I tillämpliga fall: <br />
                    Det krävs att man behåller ett eller flera konton för att registrera både
                    betalningstransaktioner och kreditutnyttjanden
                  </TermsHeadingText>
                  <ModalParagraph>Ej tillämpbart</ModalParagraph>
                </div>

                <div>
                  <TermsHeadingText as="h3">
                    I tillämpliga fall: <br />
                    Kostnad för att använda ett särskilt betalningsmedel (t.ex. ett kreditkort)
                  </TermsHeadingText>
                  <ModalParagraph>Ej tillämpbart</ModalParagraph>
                </div>

                <div>
                  <TermsHeadingText as="h3">
                    I tillämpliga fall: <br />
                    Andra kostnader i samband med kreditavtalet
                  </TermsHeadingText>
                  <ModalParagraph>Inga ytterligare kostnader.</ModalParagraph>
                </div>

                <div>
                  <TermsHeadingText as="h3">
                    I tillämpliga fall: <br />
                    Villkor för att ändra de ovan nämnda kostnaderna i samband med kreditavtalet
                  </TermsHeadingText>
                  <ModalParagraph>Ej tillämpbart</ModalParagraph>
                </div>

                <div>
                  <TermsHeadingText as="h3">
                    I tillämpliga fall: <br />
                    Skyldighet att betala notariatsavgifter
                  </TermsHeadingText>
                  <ModalParagraph>
                    Kreditgivaren tar ut en avgift när Kredittagaren har ett outnyttjat kreditutrymme.
                    Avgiften är 95 kronor per varje månad som kreditutrymmet är outnyttjat.
                  </ModalParagraph>
                  <ModalParagraph>
                    Det första Uttaget Kredittagaren gör är kostnadsfritt. För varje tillkommande Uttag
                    Kredittagaren gör tillkommer en avgift på 195 kronor.
                  </ModalParagraph>
                  <ModalParagraph>
                    Om Kredittagaren återkallar sitt autogiromedgivande tillkommer en kostnad om 249 kronor
                    per månad fram till dess att Krediten är betald i sin helhet.
                  </ModalParagraph>
                  <ModalParagraph>
                    Kreditgivaren tar ut en månadsavgift enligt tabellen nedan under den tid Kreditavtalet med
                    Kredittagaren är gällande.
                  </ModalParagraph>

                  <div style={{ overflowX: 'auto' }}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Kreditutrymme (kr)</th>
                          <th>Månatlig avgift</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableValues.map(dataArray => (
                          <tr key={dataArray[0]}>
                            <td>{dataArray[0]}</td>
                            <td>{formatCurrency(Number(dataArray[1]), { currency: 'SEK' })}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>

                <div>
                  <TermsHeadingText as="h3">Kostnader i samband med försenade betalningar</TermsHeadingText>
                  <ModalParagraph>
                    <em>
                      Uteblivna betalningar kan få allvarliga konsekvenser (t.ex. tvångsförsäljning) och
                      försvåra möjligheterna till att erhålla kredit.
                    </em>
                  </ModalParagraph>
                </div>
                <ModalParagraph>
                  Kredittagaren debiteras dröjsmålsränta på det totala förfallna månadsbeloppet inklusive den
                  del som utgör ränta från förfallodagen till dess att Kreditgivaren erhåller full betalning.
                  Kreditgivaren har även rätt att påföra en påminnelseavgift samt lagstadgade
                  inkassokostnader.
                </ModalParagraph>
                <ul>
                  <li>
                    <Text as="span">Påminnelsekostnad: 60 kr</Text>
                  </li>
                  <li>
                    <Text as="span">Inkassokostnad: 180 kr</Text>
                  </li>
                  <li>
                    <Text as="span">Dröjsmålsränta: 30% nominell årsränta över referensräntan</Text>
                  </li>
                </ul>
                <ModalParagraph>
                  Om Krediten överlämnas till Kronofogdemyndigheten tillkommer ytterligare lagstadgade
                  kostnader för uteblivna betalningar.
                </ModalParagraph>
              </Stack>
            )}
          </Translation>
        )
      },
      {
        question: 'Andra viktiga rättsliga aspekter',
        answer: (
          <Stack spacing={8}>
            <div>
              <TermsHeadingText as="h3">Ångerrätt</TermsHeadingText>
              <ModalParagraph>
                <em>Ni har rätt att frånträda kreditavtalet inom 14 kalenderdagar.</em>
              </ModalParagraph>
            </div>
            <ModalParagraph>Ja</ModalParagraph>

            <div>
              <TermsHeadingText as="h3">Förtidsåterbetalning</TermsHeadingText>
              <ModalParagraph>
                <em>Hela eller delar av krediten kan återbetalas i förtid när som helst.</em>
              </ModalParagraph>
            </div>
            <ModalParagraph>
              Kredittagaren kan när som helst återbetala hela eller delar av krediten.
            </ModalParagraph>

            <div>
              <TermsHeadingText as="h3">
                I tillämpliga fall:
                <br />
                Kreditgivaren har rätt till kompensation vid förtidsåterbetalning
              </TermsHeadingText>
              <ModalParagraph>Nej</ModalParagraph>
            </div>

            <div>
              <TermsHeadingText as="h3">Sökning i en databas</TermsHeadingText>
              <ModalParagraph>
                <em>
                  Om en kreditansökan avvisas på grundval av en sökning i en databas måste kreditgivaren
                  genast och kostnadsfritt meddela dig resultatet av sökningen. Detta gäller inte om
                  utlämnandet av sådan information är förbjudet enligt gemenskapslagstiftningen eller strider
                  mot allmän ordning och säkerhet.
                </em>
              </ModalParagraph>
            </div>
            <ModalParagraph>Ja</ModalParagraph>

            <div>
              <TermsHeadingText as="h3">Rätt att få ett utkast till ett kreditavtal</TermsHeadingText>
              <ModalParagraph>
                <em>
                  Du har rätt att på begäran kostnadsfritt få en kopia av ett utkast till ett kreditavtal.
                  Denna bestämmelse är inte tillämplig om kreditgivaren vid tidpunkten för begäran är ovillig
                  att ingå kreditavtalet med dig.
                </em>
              </ModalParagraph>
            </div>
            <ModalParagraph>
              Ja, vid ansökningstillfället presenterar Kreditgivaren ett Kreditavtal som Kredittagaren måste
              godkänna. Enbart om Kredittagaren signerar avtalet kan kredit beviljas.
            </ModalParagraph>

            <div>
              <TermsHeadingText as="h3">
                I tillämpliga fall:
                <br />
                Den tid kreditgivaren är bunden av skyldigheterna som uppkommit innan avtalet ingåtts
              </TermsHeadingText>
              <ModalParagraph>Ej tillämpbart</ModalParagraph>
            </div>
          </Stack>
        )
      },
      {
        question: 'Ytterligare information när det gäller distansförsäljning av finansiella tjänster',
        answer: (
          <Stack spacing={32}>
            <Stack>
              <TermsStyledHeading as="h2" size="m">
                a) Beträffande kreditgivaren
              </TermsStyledHeading>
              <div>
                <TermsHeadingText as="h3">
                  I tillämpliga fall:
                  <br />
                  Kreditgivarens företrädare i den medlemsstat där du är bosatt
                </TermsHeadingText>
                <ModalParagraph>Nordiska Kreditmarknadsaktiebolaget (publ)</ModalParagraph>
              </div>
              <div>
                <TermsHeadingText as="h3">E-post</TermsHeadingText>
                <ModalParagraph>Kontakt kan ske via e-post till info@nordiska.se.</ModalParagraph>
              </div>

              <div>
                <TermsHeadingText as="h3">
                  I tillämpliga fall:
                  <br />
                  Registrering
                </TermsHeadingText>
                <ModalParagraph>
                  Kreditförmedlaren samt Kreditgivaren är registrerade hos Bolagsverket med
                  organisationsnummer 559084-1101 respektive 556760-6032.
                </ModalParagraph>
              </div>

              <div>
                <TermsHeadingText as="h3">
                  I tillämpliga fall:
                  <br />
                  Den berörda tillsynsmyndigheten
                </TermsHeadingText>
                <ModalParagraph>Finansinspektionen</ModalParagraph>
              </div>
            </Stack>

            <Stack>
              <TermsStyledHeading as="h2" size="m">
                b) Beträffande kreditavtalet
              </TermsStyledHeading>

              <div>
                <TermsHeadingText as="h3">
                  I tillämpliga fall:
                  <br />
                  Utnyttjande av ångerrätten
                </TermsHeadingText>
                <ModalParagraph>
                  Kredittagare som vill åberopa sin ångerrätt kan göra detta i inloggat läge på Mina sidor på
                  www.fairlo.se och följa instruktionerna. Kredittagaren har då 30 dagar på sig att återbetala
                  eventuell utnyttjad kredit samt upplupna räntor, räknat från och med den dagen då
                  Kredittagaren fick tillgång till kreditbeloppet till och med den dag då krediten
                  återbetalas.
                </ModalParagraph>
              </div>

              <div>
                <TermsHeadingText as="h3">
                  I tillämpliga fall:
                  <br />
                  Den lagstiftning som kreditgivaren använt som grundval för att upprätta förbindelser med dig
                  innan kreditavtalet ingicks
                </TermsHeadingText>
                <ModalParagraph>
                  Kreditförmedlaren tillämpar vid var tid gällande lagstiftning för konsumentkrediter,
                  innefattande lagstiftning, allmänna råd, rekommendationer och föreskrifter.
                </ModalParagraph>
              </div>

              <div>
                <TermsHeadingText as="h3">
                  I tillämpliga fall:
                  <br />
                  Klausul om tillämplig lagstiftning för kreditavtalet och/ eller behörig domstol
                </TermsHeadingText>
                <ModalParagraph>
                  Svensk rätt tillämpas och svenska domstolar är behöriga att pröva eventuella tvister om
                  krediten. Kreditförmedlaren äger dock rätt att väcka talan i annan jurisdiktion än Sverige,
                  i den mån det krävs för att tillvarata Kreditförmedlarens eller Kreditgivarens rättigheter
                  enligt kreditavtalet.
                </ModalParagraph>
              </div>

              <div>
                <TermsHeadingText as="h3">
                  I tillämpliga fall:
                  <br />
                  Språkordning
                </TermsHeadingText>
                <ModalParagraph>
                  Information och avtalsvillkor lämnas på svenska. Kommunikation sker på svenska.
                </ModalParagraph>
              </div>
            </Stack>
            <Stack>
              <TermsStyledHeading as="h2" size="m">
                c) Beträffande prövning
              </TermsStyledHeading>

              <div>
                <TermsHeadingText as="h3">
                  Förekomst av och tillgång till en mekanism för klagomål och prövning utanför domstol
                </TermsHeadingText>
                <ModalParagraph>
                  Om Kredittagaren är missnöjd bör denne i första hand kontakta Kreditförmedlarens kundservice
                  på www.fairlo.se.
                </ModalParagraph>
              </div>
              <ModalParagraph>
                Om Kredittagaren efter att ha kontaktat kundservice fortfarande inte är nöjd kan Kredittagaren
                upprätta ett skriftligt kundklagomål till Kreditförmedlarens Kundombudsman via e-post på
                kundombud@fairlo.se. En blankett för detta finns på Kreditförmedlarens hemsida www.fairlo.se.
                Kreditförmedlaren hanterar klagomålen i enlighet med Finansinspektionens allmänna råd om
                klagomålshantering avseende finansiella tjänster till konsumenter.
              </ModalParagraph>
              <ModalParagraph>
                Kreditförmedlaren vill upplysa om att Kredittagaren kan få hjälp och kostnadsfri vägledning
                från Konsumenternas Bank- och finansbyrå och Konsumenternas Försäkringsbyrå samt den kommunala
                konsumentvägledningen.
              </ModalParagraph>
              <ModalParagraph>
                Om Kredittagaren är missnöjd med Fairlos beslut i ett enskilt ärende kan Kredittagaren få
                saken prövad av Allmänna reklamationsnämnden med postadress Box 174, 101 23 Stockholm, telefon
                08-508 860 00. Mer information om Allmänna reklamationsnämnden går att finna på dess hemsida
                www.arn.se.
              </ModalParagraph>
            </Stack>
          </Stack>
        )
      }
    ]
  },
  'en-GB': {
    name: 'SEKKI',
    readMore: false,
    questions: []
  }
};

const SekkiCredit = () => (
  <Modal title={sekki['sv-SE'].name}>
    {sekki['sv-SE'].questions.map(question => (
      <div key={question.question}>
        <Stack>
          <TermsHeadingText as="h4">{question.question}</TermsHeadingText>
          <div>{question.answer}</div>
        </Stack>
      </div>
    ))}
  </Modal>
);

export default SekkiCredit;
