import { useDevCycleClient } from '@devcycle/react-client-sdk';
import { useCallback } from 'react';
import { trackUser } from '../analytics';
import { reportException } from '../utils/error';

const useIdentifyUser = () => {
  const devCycleClient = useDevCycleClient();

  return useCallback(
    (id: string) => {
      void Promise.all([trackUser(id), devCycleClient.identifyUser({ user_id: id })]).catch(error => {
        reportException(error, { level: 'warning' });
      });
    },
    [devCycleClient]
  );
};

export default useIdentifyUser;
